
@import url('https://fonts.cdnfonts.com/css/beautiful-people-personal-use');


html, body{
  font-family: 'Beautiful People Personal Use', sans-serif;
}

.App {
  text-align: center;
  min-height: 100vh;
  font-family:"BeautifulPeople";
  font-family:"Beautiful-People";
  background-color: #e0256a;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:20px;
  text-align: center;
  font-family: 'Beautiful People Personal Use', sans-serif;
}

.App a{
  text-decoration: none;
  padding-bottom:2px; 

}

h1{
  
  border-bottom:5px solid white;
    font-size:45px;
    color: white;
    display: flex;
    align-items: center;
}

h1 svg{
  margin-left:10px;
}


.App h1 img{
    width:30px;
    height:30px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
